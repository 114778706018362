import { DetailedHTMLProps, TextareaHTMLAttributes } from 'react';

export interface ITextAreaProps extends DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  className?: string;
  name?: string;
  textareaClass?: string;
  rows?: number;
  inputRef?: any;
}

const FormTextArea = ( { className, textareaClass, rows = 5, inputRef, ...props }: ITextAreaProps ) => {
  return (
    <label className={ className }>
      <textarea
        ref={ inputRef }
        rows={ rows }
        defaultValue={ props.defaultValue }
        className={ textareaClass }
        { ...props }
      />
    </label>
  );
};

export default FormTextArea;
