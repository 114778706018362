import React from 'react';
import styles from './HeaderAll.module.scss';
import { Link } from 'react-router-dom';
import logo from '../../../../static/imgs/icons/menuIcons/logo.svg';
import SearchBarAll from '../../SearchBar/General/SearchBarAll';
import cn from 'classnames';
import heart from '../../../../static/imgs/icons/menuIcons/heart.svg';
import bag from '../../../../static/imgs/icons/menuIcons/bag1.svg';
import defaultAvatar from '../../../../static/imgs/icons/menuIcons/profile.svg';
import { v4 as uuidv4 } from 'uuid';
import ThemeButton from './ThemeButton';
import LanguageSelect from '../../LanguageSelect/LanguageSelect';
import Address from '../../Profile/Address';
import { useStore } from 'effector-react';
import { $userInfo } from '../../../../store/account';
import { ReactComponent as Crest } from '../../../../static/imgs/icons/menuIcons/bar.svg';
import { ReactComponent as Menu } from '../../../../static/imgs/icons/menuIcons/menu.svg';
import { $menuProfile, updateMenuProfile } from '../../../../store/menu';

const onlineData = [
  { id: uuidv4(), value: 'Offline' },
  { id: uuidv4(), value: 'Online' },
];

const HeaderAll = () => {
  const userInfo = useStore( $userInfo );
  const toggleMenuProfile = useStore( $menuProfile );

  const toOpenMenu = () => {
    updateMenuProfile( true );
  };

  const toCloseMenu = () => {
    updateMenuProfile( false );
  };

  return (
    <div className={ styles.header }>
      <Link to={ '/' }>
        <img className={ styles.logo } width="86" height="120" src={ logo } alt="Happy Bunny"/>
      </Link>

      <div className={ styles.hideMenu }>
        <div className={ styles.stockSearchBar }>
          <div className={ styles.searchBarWrapper }>
            <SearchBarAll/>
          </div>

          {/*<div className={ styles.stock }>*/ }
          {/*  Get 10 ETH to first order <b>SEX999</b>*/ }
          {/*</div>*/ }
        </div>

        <nav className={ styles.nav }>
          <Link to={ '/' } className={ cn( styles.region, styles.menuItem ) }>
            <Address/>
          </Link>

          <ThemeButton useClass={ true }/>

          <Link to={ '/profile/favorites' } className={ styles.menuItem }>
            <img width="25" height="25" src={ heart } alt="Like" className={ styles.iconHeart }/>
          </Link>

          <Link to={ '/cart' } className={ styles.menuItem }>
            <img width="25" height="25" src={ bag } alt="Cart" className={ styles.iconBag }/>
          </Link>

          <Link to={ '/profile' } className={ styles.menuItem }>
            <img
              width="25"
              height="25"
              src={ userInfo?.avatar || defaultAvatar }
              alt={ userInfo?.username }
              className={ styles.iconLogin }
            />
          </Link>

          <LanguageSelect className={ cn( styles.menuItem ) }/>
        </nav>
      </div>

      {/*Mobile*/ }

      { toggleMenuProfile ?
        <div className={ styles.burgerWrapper } onClick={ toCloseMenu }>
          <Menu/>
        </div>
        :
        <div className={ styles.burgerWrapper } onClick={ toOpenMenu }>
          <Crest/>
        </div>
      }

      <div className={ cn( toggleMenuProfile ? 'd-none' : styles.menuProfileMobile ) }>
        <nav className={ styles.navMobileProfile }>
          <ThemeButton useClass={ true }/>

          <div className='d-flex' onClick={ toOpenMenu }>
            <Link to={ '/profile' } className={ styles.menuItem }>
              <img
                width="25"
                height="25"
                src={ userInfo?.avatar || defaultAvatar }
                alt={ userInfo?.username }
                className={ styles.iconLogin }
              />
            </Link>
          </div>

          {/*<div className={ styles.menuItem }>$25k</div>*/ }

        </nav>
      </div>
    </div>
  );
};

export default HeaderAll;
