import React from 'react';
import cn from 'classnames';
import styles from './LanguageSelect.module.scss';
import {useStore} from 'effector-react';
import {$config} from '../../../store/config';
import { $userInfo, saveUserInfo } from '../../../store/account';

interface IProps extends React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>{
  className?: string;
}

const LanguageSelect = ({className, ...newProps}: IProps) => {
    const config = useStore($config);
    const userInfo = useStore($userInfo);

    const onChange = async (e: any) => {
      const data = new FormData();
      data.set('lang', e.target.value);
      await saveUserInfo( data );
    };

    return (
      <select
        {...newProps}
        className={ cn( styles.menuItem, styles.languages, className ) }
        onChange={onChange}
      >
        { config.languages?.map( s =>
          <option key={ s.id } value={ s.language } selected={ userInfo?.lang === s.language }>
            { s.language }
          </option>
        ) }
      </select>
    );
};

export default LanguageSelect;
