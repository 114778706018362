import { apiGet } from '../api';
import { IConfig } from '../types/config';
import { createEvent, createStore } from 'effector';
import { updateLocalizations } from '../data/localization';

export const getConfig = async () => {
  const response = await apiGet( { url: '/config' } );
  updateLocalizations( response.labels );
  updateConfig( response );

  return response;
};

export const updateConfig = createEvent<IConfig>();

export const $config = createStore<IConfig>( { languages: [] } )
  .on( updateConfig, ( _, newState ) => newState );
