import { IApiRequest_Get, IApiRequest_Post } from '../types/api';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { API } from './global';
import { getAuthToken, logout } from '../store/auth';
import { $address } from '../store/account';
import { sample } from 'effector';
import { updateAlert } from '../store/alert';
import { updatePreloader } from '../store/preloader';

export const Api = axios.create( {
  baseURL: API.url,
  timeout: API.timeout,
} );

export const getHeaders = ( token: string, json?: boolean ) => {
  const headers: any = {};
  if ( json ) {
    // @ts-ignore
    headers[ 'Content-Type' ] = 'application/json';
  }

  let address = sample( { source: $address, target: $address } ).getState();
  if ( address.cityId ) {
    headers[ 'CityId' ] = String( address.cityId );
    headers[ 'Address' ] = encodeURIComponent( address.address );
  }

  const referalCode = localStorage.getItem( 'referalCode' );
  if ( referalCode ) {
    headers[ 'ReferalCode' ] = referalCode;
  }

  if ( token ) {
    // @ts-ignore
    headers.Authorization = `Bearer ${ token }`;
  }

  if ( typeof navigator !== 'undefined' ) {
    headers.language = navigator.language;
  }

  return headers;
};

Api.interceptors.request.use( ( config: AxiosRequestConfig ) => {
  const token = getAuthToken();

  config.headers = { ...config.headers, ...getHeaders( token, !( config.data instanceof FormData ) ) };

  return config;
} );

const successHandler = ( response: any ) => {
  // updateApiLoading(false);

  if ( response.status === 401 ) {
    logout();
  }

  if ( response.status === 400 ) {
    updateAlert( {
      type: 'danger',
      message: response.data.message
    } );
  }

  updatePreloader( false );

  return response.data;
};

const errorHandler = ( error: AxiosError ) => {
  //   updateApiLoading(false);

  if ( error.response?.status === 401 ) {
    logout();
  }

  if ( error.response?.status === 400 ) {
    updateAlert( {
      type: 'danger',
      // @ts-ignore
      message: error.response?.data?.message
    } );
  }

  updatePreloader( false );

  throw error;
};

export const apiPost = async (
  {
    url,
    postData,
    loading,
    loadingHide,
    viewAlert,
  }: IApiRequest_Post ) => {
  if ( loading ) {
    // updateApiLoading(true);
  }
  return await Api.post( url, postData )
    .then( res => successHandler( res ) )
    .catch( e => errorHandler( e ) );
};

export const apiGet = async ( { url }: IApiRequest_Get ) => {
  return Api.get( url )
    .then( res => successHandler( res ) )
    .catch( e => errorHandler( e ) );
};

export const apiPatch = async (
  {
    url,
    postData,
    loading = true,
    loadingHide = true,
    viewAlert = true,
  }: IApiRequest_Post ) => {
  if ( loading ) {
    // updateApiLoading( true );
  }

  return await Api.patch( url, postData )
    .then( res => successHandler( res ) )
    .catch( e => errorHandler( e ) );
};

export const apiDelete = async (
  {
    url,
    postData,
    loading = true,
    loadingHide = true,
    viewAlert = true,
  }: IApiRequest_Post ) => {
  if ( loading ) {
    // updateApiLoading( true );
  }

  return await Api.delete( url, postData )
    .then( res => successHandler( res ) )
    .catch( e => errorHandler( e ) );
};
