import React, { useEffect, useState } from 'react';
import styles from './ClientAgeConfirm.module.scss';
import { Modal } from '../Modal';
import SimpleButton from '../../Buttons/Button/Button';
import { localization } from '../../../../data/localization';
import { hideModal, showModal } from '../../../../store/modal';

const ClientAgeConfirm = () => {
  const [ disabled, setDisabled ] = useState( false );

  const confirm = () => {
    localStorage.setItem( 'age_confirm', '1' );
    hideModal( 'client_age_confirm' );
  };

  const reject = () => {
    setDisabled( true );
    localStorage.removeItem( 'age_confirm' );
  };

  useEffect( () => {
    if ( !localStorage.getItem( 'age_confirm' ) ) {
      showModal( 'client_age_confirm' );
    }
  }, [] );


  return (
    <Modal
      storeKey={ 'client_age_confirm' }
      modalClass={ styles.modalClass }
      wrapperClass={ styles.wrapperClass }
      cantClose={ true }
    >
      <div className={ styles.top }>
        <div className={ styles.text }>
          { !disabled ?
            localization.age.text
            :
            localization.age.text2
          }
        </div>

        <div className={ styles.down }>

          <div className={ styles.btnsWrapper }>
            <SimpleButton
              text={ localization.age.confirm }
              className={ styles.btnClass }
              onClick={ confirm }
            />
            <SimpleButton
              text={ localization.age.reject }
              className={ styles.btnClass }
              onClick={ reject }
            />
          </div>

        </div>
      </div>
    </Modal>
  );
};

export default ClientAgeConfirm;
