import React from 'react';
import styles from './Address.module.scss';
import { useStore } from 'effector-react';
import { $userInfo } from '../../../store/account';
import { $modelInfo } from '../../../store/model';
import { $brothelInfo } from '../../../store/company';

const Address = () => {
  const profile = useStore( $userInfo );
  const model = useStore( $modelInfo );
  const brothel = useStore( $brothelInfo );

  const city = profile?.city || model?.city || brothel?.city || 'Select Address';
  const address = (profile?.address || model?.address || brothel?.address || '').split( ',' )[ 0 ];

  return <div className={ styles.fullAddress }>{ city + ', ' + address }</div>;
};

export default Address;
