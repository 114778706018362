import React from 'react';
import styles from './HeaderHome.module.scss';
import { Link } from 'react-router-dom';
import logo from '../../../../static/imgs/icons/menuIcons/logo.svg';
import HeaderHomeItems from './HeaderHomeItems';

const HeaderHome = () => {

  return (
    <div className={ styles.header }>
      <Link to={ '/' }>
        <img className={ styles.logo } src={ logo } alt="Happy Bunny"/>
      </Link>
      <HeaderHomeItems/>
    </div>
  );
};

export default HeaderHome;
