import React from 'react';
import styles from './Tags.module.scss';
import { localization } from '../../../../../data/localization';
import { IQuestionnaireTags } from '../../../../../types/questionnaires';

interface IProps {
  questionnaire?: IQuestionnaireTags[] | [];
}

const Tags = ( { questionnaire }: IProps ) => {
  return (
    <div className={ styles.tagsInner }>
      <div className={ styles.tagsTitle }>{ localization.profile.tags }</div>
      <div className={ styles.tagsWrapper }>
        { questionnaire?.map( tag =>
          <div key={ tag.id } className={ styles.tags }>{ tag.name }</div>
        ) }
      </div>
    </div>
  );
};

export default Tags;
