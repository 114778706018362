import React from 'react';
import Spinner from '../Spinner/Spinner';
import { useStore } from 'effector-react';
import { $preloader } from '../../../store/preloader';

const Preloader = () => {
  const preloader = useStore( $preloader );

  if ( !preloader ) return <></>;

  return (
    <Spinner/>
  );
};

export default Preloader;
