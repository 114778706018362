import { apiPost } from '../api';
import { createEvent, createStore } from 'effector';
import { IPayment } from '../types/payments';
import { showModal } from './modal';

export const paymentModalKey = 'PaymentModal';
export const showPaymentModal = createEvent<IPayment>();
export const $paymentModal = createStore<IPayment | null>( null )
  .on( showPaymentModal, ( state, newState ) => {
    showModal( paymentModalKey );
    return newState;
  } );


export const createPayment = async ( amount: number ) => {
  return await apiPost( {
    url: '/payments',
    postData: {
      amount: amount
    }
  } );
};
