import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import MobilePage from '../../pages/MobileUserPage/MobileUserPage';
import GuestRoute from './GuestRoute';
import AuthRoute from './AuthRoute';
import NotFound from '../../pages/NotFound/NotFound';
import ReferalsInit from '../../pages/ReferalsInit';

const Confirm = lazy( () => import('../../pages/Confirm/Confirm') );
const Login = lazy( () => import('../../pages/Login/Login') );
const ForgotPassword = lazy( () => import('../../pages/ForgotPassword/ForgotPassword') );
const RegistrationSeller = lazy( () => import('../../pages/Registration/RegistrationSeller/RegistrationSeller') );
const RegistrationClient = lazy( () => import('../../pages/Registration/RegistrationClient/RegistrationClient') );
const CheckEmail = lazy( () => import('../../pages/CheckEmail/CheckEmail') );

const Home = lazy( () => import('../../pages/Home/Home') );

const Questionnaire = lazy( () => import('../../pages/Questionnaire/Questionnaire') );
const Brothel = lazy( () => import('../../pages/Brothel/Brothel') );
const Client = lazy( () => import('../../pages/Client/Client') );

const Profile = lazy( () => import('../../pages/Profile/Profile') );
const CartNewOrder = lazy( () => import('../../pages/Cart/Cart') );

const MainStack = () => {
  return (
    <Routes>
      <Route path="/" element={ <Home/> }/>

      <Route path="/confirm/:token" element={ <Confirm/> }/>
      <Route path="/login" element={ <GuestRoute Component={ Login }/> }/>

      <Route path="/referals/:code" element={ <ReferalsInit/> }/>

      <Route path="/registration" element={ <GuestRoute Component={ RegistrationClient }/> }/>
      <Route path="/registration_seller" element={ <GuestRoute Component={ RegistrationSeller }/> }/>
      <Route path="/forgot_password" element={ <GuestRoute Component={ ForgotPassword }/> }/>
      <Route path="/check_email" element={ <GuestRoute Component={ CheckEmail }/> }/>

      <Route path="/questionnaires/:id" element={ <Questionnaire/> }/>
      <Route path="/brothels/:id" element={ <Brothel/> }/>
      <Route path="/clients/:id" element={ <Client/> }/>

      <Route path="/profile/*" element={ <AuthRoute Component={ Profile }/> }/>

      <Route path="/cart" element={ <CartNewOrder/> }/>

      <Route path="/mobile_page" element={ <MobilePage/> }/>

      <Route path="*" element={ <NotFound/> }/>
    </Routes>
  );
};

export default MainStack;
