import React, { useState } from 'react';

interface IProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  placeholder: string;
}

const CustomImage = ( { src, placeholder, style, ...newProps }: IProps ) => {
  const [ state, setState ] = useState( false );

  const newStyle: any = { ...style, opacity: 0, position: 'absolute', pointerEvents: 'none' };
  const onError = ( e: any ) => {
    setState( false );
  };

  return (
    <>
      <div style={ { display: 'flex', justifyContent: 'flex-end' } }>
        <img
          style={ !state ? newStyle : style }
          onError={ onError }
          onLoad={ () => setState( true ) }
          { ...newProps }
          alt=''
          src={ src }
        />
      </div>
      <div style={ { display: 'flex', justifyContent: 'flex-end' } }>
        <img
          style={ state ? newStyle : style }
          { ...newProps }
          alt=''
          src={ placeholder }
        />
      </div>
    </>
  );
};

export default CustomImage;
