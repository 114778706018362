import { ILabel } from '../types/config';

export const localization: any = {};

export const updateLocalizations = ( data: ILabel[] ) => {
  data.forEach( function ( k ) {
    let prop = k.code.split( '.' );
    let last = prop.pop();
    prop.reduce( function ( o, key ) {
      return o[ key ] = o[ key ] || {};
      // @ts-ignore
    }, localization )[ last ] = k.text;
  } );
};
 
