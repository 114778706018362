import React from 'react';
import styles from './HeaderHome.module.scss';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { $auth } from '../../../../store/auth';
import LanguageSelect from '../../LanguageSelect/LanguageSelect';
import ThemeButton from '../HeaderAll/ThemeButton';
import { useStore } from 'effector-react';
import { localization } from '../../../../data/localization';
import Address from '../../Profile/Address';

const HeaderHomeItems = () => {
  const auth = useStore( $auth );

  return (
    <nav className={ styles.menu }>
      <ThemeButton useClass={ true }/>

      { !auth &&
        <>
          <Link to={ '/registration' } className='header-menu-item'>
            { localization.buttons.signUp }
          </Link>

          <Link to={ '/login' } className='header-menu-item'>
            { localization.buttons.signIn }
          </Link>
        </>
      }
      { auth &&
        <>
          <Link to={ '/' } className={ cn( styles.region, 'header-menu-item' ) }>
            <Address/>
          </Link>

          <Link to={ '/profile' } className='header-menu-item'>
            Account
          </Link>
        </>
      }
      <LanguageSelect className='header-menu-item'/>
    </nav>
  );
};

export default HeaderHomeItems;
