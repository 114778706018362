import React from 'react';
import {useStore} from 'effector-react';
import {Navigate} from 'react-router-dom';
import { $auth } from '../../store/auth';
import {$userInfo} from '../../store/account';

interface IRouteProps {
  roles?: string[];
  Component: React.ComponentType<any>;
}

const AuthRoute = ({ roles, Component }: IRouteProps) => {
  const isAuth = useStore($auth);
  const userInfo = useStore($userInfo);

  if(!roles || !roles.length) {
    return isAuth ? <Component /> : <Navigate to="/login" />
  }

  if(userInfo?.isClient && roles?.indexOf('client') === -1) {
    return <Navigate to="/" />;
  }

  if(userInfo?.isModel && roles?.indexOf('model') === -1) {
    return <Navigate to="/" />;
  }

  if(userInfo?.isCompany && roles?.indexOf('company') === -1) {
    return <Navigate to="/" />;
  }

  return <></>;
}

export default AuthRoute;
