import React from 'react';
import simpleImageGirl from '../../../../static/imgs/simpleImageGirl.svg';
import simpleImageBoy from '../../../../static/imgs/simpleImageBoy.svg';
import CustomImage from '../../CustomImage/CustomImage';

interface IProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  gender: string;
}

const CustomModelImage = ( { src, gender, ...newProps }: IProps ) => {
  let placeholder = simpleImageGirl;

  switch ( gender ) {
    case 'male' :
      placeholder = simpleImageBoy;
      break;

    default:
  }

  return (
    <CustomImage
      { ...newProps }
      placeholder={ placeholder }
      src={ src }
    />
  );
};

export default CustomModelImage;
