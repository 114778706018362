import React from 'react';
import styles from './MobileUserPage.module.scss';
import { v4 as uuidv4 } from 'uuid';
import Balance from '../Profile/Balance/Balance';
import Promo from '../Profile/Promo/Promo';
import ModelHistory from '../Profile/Model/ModelHistory/ModelHistory';
import { ReactComponent as CollapseArrow } from '../../static/imgs/icons/collapseArrow.svg';
import ViewInfoAbout from '../../components/common/ViewInfoAbout/ViewInfoAbout';

const userMenuData = [
  { id: uuidv4(), text: 'Profile', value: <ViewInfoAbout/> },
  { id: uuidv4(), text: 'Balance', value: <Balance/> },
  { id: uuidv4(), text: 'Promotion services', value: <Promo/> },
  { id: uuidv4(), text: 'History', value: <ModelHistory/> },
  { id: uuidv4(), text: 'Messages', value: <>Lorem ipsum dolor sit amet, consectetur adipisicing.</> },
];

const MobileUserPage = () => {
  return (
    <div className={ styles.collapseContainer }>
      <div className={ styles.collapseRow }>
        { userMenuData.map( s =>
          <div key={ s.id } className={ styles.collapseItem }>
            <input id={ s.text } className={ styles.keyBig } type="checkbox"/>
            <label htmlFor={ s.text } className={ styles.collapseLabel }>
              { s.text }
              <CollapseArrow/>
            </label>
            <div className={ styles.inner }>
              { s.value }
            </div>
          </div>
        ) }
      </div>
    </div>
  );
};

export default MobileUserPage;
