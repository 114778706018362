import EchoClient from 'laravel-echo';
import { getAuthToken } from '../store/auth';
import { IUserInfo } from '../types/account';
import { clearCart, updateCart, updateCartWait } from '../store/cart';
import { ICart, ICartWaiting } from '../types/cart';
import { getAppUrl } from '../api/global';
import { getSubscriptions } from '../store/chats';

//@ts-ignore
// eslint-disable-next-line no-undef
window.io = require( 'socket.io-client' );

export const initializeEcho = ( userInfo: IUserInfo ) => {
  const Echo = new EchoClient( {
    namespace: '',
    broadcaster: 'socket.io',
    host: `${ getAppUrl() }:6001`,
    auth:
      {
        headers:
          {
            'Authorization': 'Bearer ' + getAuthToken()
          }
      }
  } );

  Echo.private( `events.${ userInfo.id }` )
    .listen( '.cart.updated', ( e: ICart ) => {
      if ( typeof e?.status !== 'undefined' ) {
        updateCart( e );
      } else {
        clearCart();
      }
    } )
    .listen( '.cart.waiting', ( e: ICartWaiting ) => {
      updateCartWait( e );
    } )
    .listen( '.chat.update', ( e: any ) => {
      getSubscriptions().map( callback => callback( e.message ) );
    } );
};
