import React from 'react';
import styles from './AboutMe.module.scss';
import { localization } from '../../../../../data/localization';

interface IProps {
  about?: string;
}

const AboutMe = ( { about }: IProps ) => {
  return (
    <div className={ styles.aboutMe }>
      <div className={ styles.title }>{about && localization.profile.aboutMe }</div>
      <p className={ styles.text }>{ about }</p>
    </div>
  );
};

export default AboutMe;
