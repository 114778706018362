import { apiGet, apiPost } from '../api';

export const createWithdrawRequest = async ( amount: number, comment: string ) => {
  return await apiPost( {
    url: '/withdrawRequests',
    postData: {
      amount: amount,
      comment: comment
    }
  } );
};

export const getWithdrawRequests = async () => {
  return await apiGet( {
    url: '/withdrawRequests'
  } );
};
