import { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import {randomString} from '../../../util/randomString';

interface IProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label: string;
  inputClass?: string;
  labelClass?: string;
}

const FormCheckbox = ({ label, className, inputClass, ...newProps}: IProps ) => {
  const id = randomString(10);

  return (
    <div className={ className }>
      <input
        className={ inputClass }
        type="radio"
        {...newProps}
        id={ id }
      />
      <label htmlFor={ id }>{ label }</label>
    </div>
  );
};

export default FormCheckbox;
