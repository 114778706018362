import React from 'react';
import styles from './ModelHistory.module.scss';
import { useStore } from 'effector-react';
import { $userInfo } from '../../../../store/account';

const ModelHistory = () => {
  const userInfo = useStore( $userInfo );

  return (
    <div className='w-100-p profilePageWrapper'>

      <div className={ styles.wrapper }>
        <div className={ styles.divider }/>
        <div className={ styles.email }>{ userInfo?.email }</div>

        <div className={ styles.historyWrapper }>
          {/*<ModelOrder/>*/ }
        </div>
      </div>
    </div>
  );
};

export default ModelHistory;
