import React from 'react';
import styles from './IncludedServices.module.scss';
import { useTheme } from '../../../../hooks/useTheme';
import { IQuestionnairePrice } from '../../../../types/questionnaires';

interface IProps {
  price?: IQuestionnairePrice | null;
}

const IncludedServices = ( { price }: IProps ) => {
  const { theme } = useTheme();

  return (
    <div
      className={ styles.wrapperVariants }
      style={ theme === 'dark' ? { background: 'transparent' } : { background: 'rgba(175, 175, 175, 0.3)' } }
    >
      { price?.serviceGroups.filter( s => s.services.find( j => j.included ) ).map( serviceGroup =>
        <div key={ serviceGroup.name } className={ styles.innerVariants }>
          <div className={ styles.titleVariants }>{ serviceGroup.name }</div>
          { serviceGroup.services.filter( s => s.included ).map( service => (
            <div key={ service.id } className={ styles.variant }>{ service.name }</div>
          ) ) }
        </div>
      ) }
    </div>
  );
};

export default IncludedServices;