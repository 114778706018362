import React from 'react';
import styles from './InfoAboutAside.module.scss';
import { IQuestionnaire } from '../../../../types/questionnaires';
import ProductPhotoSlider from '../../Sliders/ProductPhotoSlider/ProductPhotoSlider';
import VideoPlayer from '../../../../pages/Profile/Model/Video/VideoPlayer';
import AboutMe from './AboutMe/AboutMe';
import CustomModelImage from '../../UI/CustomModelImage/CustomModelImage';
import { clone } from '../../../../util/util';
import { staticResource } from '../../../../util/resources';

interface IProps {
  questionnaire?: IQuestionnaire | null;
}

const InfoAboutAside = ( { questionnaire }: IProps ) => {
  let photos = clone( questionnaire?.photos || [] );
  if ( questionnaire?.photo ) photos.unshift( questionnaire?.photo );

  return (
    <div className='w-100-p'>

      <div className={ styles.photoWrapper }>
        { questionnaire?.photos?.length
          ? <ProductPhotoSlider gender={ questionnaire?.gender || '' } photos={ photos }/>
          : <CustomModelImage gender={ questionnaire?.gender || '' }
                              src={ staticResource( questionnaire?.photo, 300, 500 ) } className={ styles.defaultImage }
                              alt=""/>
        }
      </div>

      <div className={ styles.aboutMeWrapper }>
        <AboutMe about={ questionnaire?.about }/>
      </div>

      <div className={ styles.videoInner }>
        <VideoPlayer video={ questionnaire?.video }/>
      </div>

    </div>
  );
};

export default InfoAboutAside;
