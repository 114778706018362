export interface IModal_VisibleStore {
  sexMenu: boolean;
  uploadImages: boolean;
  notification: boolean;
  wait: boolean;
}

export enum EModal_VisibleStore {
  SexMenu = 'sexMenu',
  UploadImages = 'uploadImages',
  OrderModelAccept = 'orderModelAccept',
  OrderClientAccept = 'orderClientAccept',
}
