import React from 'react';
import styles from './ModelCard.module.scss';
import { IQuestionnaire, IQuestionnaireCard } from '../../../../types/questionnaires';
import { useStore } from 'effector-react';
import { $userInfo, addFavorite, deleteFavorite } from '../../../../store/account';
import { randomString } from '../../../../util/randomString';

interface IProps {
  questionnaire: IQuestionnaireCard | IQuestionnaire;
}

const FavoriteIcon = ( { questionnaire }: IProps ) => {
  const userInfo = useStore( $userInfo );
  const isFavorite = userInfo?.favoriteIds && userInfo?.favoriteIds?.indexOf( questionnaire.id || '0' ) !== -1;

  const change = async () => {
    if ( isFavorite ) {
      await deleteFavorite( questionnaire.id || '0' );
    } else {
      await addFavorite( questionnaire.id || '0' );
    }
  };

  const id = randomString( 7 );

  return (
    <div key={ questionnaire.id } className={ styles.like } onClick={ ( e ) => e.stopPropagation() }>
      <input
        type="checkbox"
        id={ id }
        checked={ isFavorite }
        onChange={ change }
        // name={ questionnaire.id }
      />
      <label htmlFor={ id }/>
    </div>
  );
};

export default FavoriteIcon;
