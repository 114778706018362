export interface IWithdrawRequest {
  id: number;
  customerId: string;
  amount: number;
  comment: string;
  rejectComment: string;
  status: EWithdrawStatus;
  created_at: string;
  updated_at: string;
}

export interface IWithdrawRequests {
  count: number;
  items: IWithdrawRequest[];
}

export enum EWithdrawStatus {
  CREATED_STATUS,
  CONFIRMED_STATUS,
  REJECTED_STATUS,
}
