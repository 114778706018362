import React from 'react';
import styles from './SexItems.module.scss';
import { useTheme } from '../../../../hooks/useTheme';
import {
  IQuestionnairePricesServiceGroup,
  IQuestionnairePricesServiceGroupService
} from '../../../../types/questionnaires';
import { ICartProduct } from '../../../../types/cart';
import { clone } from '../../../../util/util';

interface IProps {
  serviceGroup?: IQuestionnairePricesServiceGroup | null;
  selected?: ICartProduct;
  setSelected?: ( value: ICartProduct ) => void;
}

const SexItems = ( { serviceGroup, selected, setSelected }: IProps ) => {
  const { theme } = useTheme();

  const onSelect = ( service: IQuestionnairePricesServiceGroupService ) => () => {
    if ( selected && setSelected ) {
      const _selected = clone( selected );
      if ( _selected.options?.find( o => o.serviceId === service.id ) ) {
        _selected.options = _selected?.options.filter( o => o.serviceId !== service.id );
      } else {
        _selected?.options?.push( { serviceId: service.id, price: service.price } );
      }
      setSelected( _selected );
    }
  };

  return (
    <div className={ styles.wrapper }>

      { serviceGroup?.services.filter( s => s.price > 0 ).map( service =>
        <div
          key={ service.id }
          className={ styles.inner }
          style={ { background: theme === 'light' ? 'rgba(175, 175, 175, 0.3)' : 'transparent' } }
        >
          <input
            onClick={ onSelect( service ) }
            type={ 'checkbox' }
            id={ service.id.toString() }
            name={ service.name }
            checked={ selected?.options?.find( o => o.serviceId === service.id ) !== undefined }
          />
          <label htmlFor={ String( service.id ) }>
            { service.name }<span>{ service.price } </span>
          </label>
        </div>
      ) }

    </div>
  );
};

export default SexItems;
