import React from 'react';

function SampleNextArrow( { style }: any ) {
  return <div style={ { ...style, display: 'none' } }/>;
}

function SamplePrevArrow( props: any ) {
  const { style } = props;
  return <div style={ { ...style, display: 'none' } }/>;
}

export const settings = {
  dots: false,
  infinite: false,
  nextArrow: <SampleNextArrow/>,
  prevArrow: <SamplePrevArrow/>,
  dotsClass: 'slick-dots custom-indicator slick-thumb',
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  speed: 1000,
  autoplaySpeed: 3000,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1201,
      settings: {
        dots: false,
      }
    },
  ]
};
