import { createEvent, createStore } from 'effector';
import { apiGet, apiPost } from '../api';
import { ILoginRequest, ILoginResponse } from '../types/auth';

const credentials = {
  token: localStorage.getItem( 'token' ) || '',
};

export const getAuthToken = () => {
  return credentials.token;
};

export const login = createEvent<boolean>();
export const logout = createEvent();

export const $auth = createStore<boolean>( !!credentials.token )
  .on( login, ( state, newState ) => {
    return true;
  } )
  .on( logout, () => {
    clearCredentials();
    return false;
  } );

export const updateCredentials = ( state: ILoginResponse ) => {
  localStorage.setItem( 'token', state.token );
  credentials.token = state.token;
  login( true );
};

const clearCredentials = () => {
  localStorage.removeItem( 'token' );
  credentials.token = '';
};

export const loginUser = async ( postData: ILoginRequest ) => {
  const response = await apiPost( {
    url: '/login',
    postData
  } );
  if ( response.token ) updateCredentials( response );

  return response;
};

export const logoutUser = async () => {
  const response = await apiGet( {
    url: '/logout'
  } );

  logout();

  return response;
};
