import React from 'react';
import styles from './Button.module.scss';
import cn from 'classnames';

interface ISimpleButtonProps {
  text: string;
  className?: string;
  onClick?: any;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
}

const SimpleButton = ( { text, className, onClick, disabled, type = 'button' }: ISimpleButtonProps ) => {
  return (
    <button className={ cn( styles.button, className ) } onClick={ onClick } disabled={ disabled } type={ type }>
      { text }
    </button>
  );
};

export default SimpleButton;
