import { createEvent, createStore, sample } from 'effector';
import { apiGet, apiPost } from '../api';
import { ICart, ICartDetailProduct, ICartProduct, ICartWaiting } from '../types/cart';
import { login, logout } from './auth';

export const updateCart = createEvent<ICart>();
export const clearCart = createEvent();

export const $cart = createStore<ICart | null>( null )
  .on( updateCart, ( state, newState ) => newState )
  .reset( clearCart );

export const updateCartWait = createEvent<ICartWaiting>();
export const clearCartWait = createEvent();

export const $cartWait = createStore<ICartWaiting | null>( null )
  .on( updateCartWait, ( state, newState ) => newState )
  .reset( clearCartWait );

login.watch( state => {
  if ( state ) {
    getCart().then();
  }
} );

logout.watch( state => {
  clearCart();
} );

export const getCart = async () => {
  const response = await apiGet( {
    url: '/cart',
  } );

  updateCart( response );

  return response;
};

export const getCartDetails = async () => {
  return await apiGet( {
    url: '/cart/details',
  } );
};

export const saveCart = async ( postData: ICart | FormData ) => {
  const response = await apiPost( {
    url: '/cart',
    postData
  } );

  updateCart( response );

  return response;
};

export const saveAddress = async ( postData: ICart | FormData ) => {
  const response = await apiPost( {
    url: '/cart/address',
    postData
  } );

  updateCart( response );

  return response;
};

export const addQuestionnaire = async ( product: ICartProduct ) => {
  const cart = sample( { source: $cart, target: $cart } ).getState();
  const products = ( cart?.products || [] ).filter( s => s.id !== product.id );

  await saveCart( { products: [ ...products, product ] } );
};


export const getCartWaiting = async () => {
  const response = await apiGet( {
    url: '/model/orders/wait',
  } );

  if ( response.id ) updateCartWait( response );

  return response;
};

export const confirmCartWaiting = async ( postData: ICartDetailProduct ) => {
  return await apiPost( {
    url: '/model/orders/confirm',
    postData
  } );
};

export const rejectCartWaiting = async ( postData: ICartDetailProduct ) => {
  return await apiPost( {
    url: '/model/orders/reject',
    postData
  } );
};
