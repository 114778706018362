import React from 'react';
import {useStore} from 'effector-react';
import {Navigate} from 'react-router-dom';
import {$auth} from '../../store/auth';

interface IRouteProps {
  Component: React.ComponentType<any>;
}

const GuestRoute = ({ Component }: IRouteProps) => {
  const isAuth = useStore($auth);

  return !isAuth ? <Component /> : <Navigate to="/" />
}

export default GuestRoute;
