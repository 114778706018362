import React, { useState } from 'react';
import styles from './Promo.module.scss';
import PromoItem from './PromoItem/PromoItem';
import { localization } from '../../../data/localization';
import { useStore } from 'effector-react';
import { $userInfo } from '../../../store/account';
import { useQuery } from 'react-query';
import { getPromotions } from '../../../store/promo';
import Spinner from '../../../components/common/Spinner/Spinner';
import { IPromotionsResponse } from '../../../types/promo';
import { $modelInfo } from '../../../store/model';

const Promo = () => {
  const userInfo = useStore( $userInfo );
  const questionnaire = useStore( $modelInfo );
  const [ update, setUpdate ] = useState( Date.now );

  const {
    data: promotions,
    isLoading
  } = useQuery<IPromotionsResponse>( [ 'promotions', questionnaire?.id, update ], () => getPromotions( questionnaire?.id || '0' ) );

  if ( isLoading ) return <Spinner/>;

  return (
    <div className='w-100-p profilePageWrapper'>

      <div className={ styles.wrapper }>
        <div className={ styles.divider }/>
        <div className={ styles.email }>{ userInfo?.email }</div>

        <div className={ styles.text }>
          { localization.common.toActivateTheService }
        </div>

        <div className={ styles.promoWrapper }>
          { questionnaire && promotions?.items.map( item =>
            <PromoItem
              key={ item.id }
              promo={ item }
              questionnaire={ questionnaire }
              setUpdate={ setUpdate }
            />
          ) }
        </div>
      </div>
    </div>
  );
};

export default Promo;
