import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const ReferalsInit = () => {
  const { code } = useParams();
  const navigate = useNavigate();

  useEffect( () => {
    if ( code ) {
      localStorage.setItem( 'referalCode', code );
      navigate( '/' );
    }
  }, [ code ] );

  return (
    <></>
  );
};

export default ReferalsInit;