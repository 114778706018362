import React from 'react';
import styles from './NotFound.module.scss';
import LinkButton from '../../components/common/Buttons/Button/LinkButton';
import { localization } from '../../data/localization';

const NotFound = () => {
  return (
    <div className={ styles.inner }>

      <div className={ styles.pink404 }>{ localization.common.e404 }</div>
      <div className={ styles.pinkText }>{ localization.common.notFound }</div>

      <LinkButton to={ '/' } text={ localization.common.goToHomePage } className={ styles.btnClass }/>
    </div>
  );
};

export default NotFound;
