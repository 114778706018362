import React, { useEffect } from 'react';
import TabContainer from '../../../Tabs/TabContainer';
import { TabPanel } from 'react-tabs';
import styles from './Prices.module.scss';
import Prices from './Prices';
import { IQuestionnaire } from '../../../../../types/questionnaires';
import { ICartProduct } from '../../../../../types/cart';
import { useStore } from 'effector-react';
import { $cart } from '../../../../../store/cart';
import Spinner from '../../../Spinner/Spinner';

interface IProps {
  questionnaire?: IQuestionnaire | null;
  selected?: ICartProduct;
  setSelected?: ( value: ICartProduct ) => void;
}

const PricesTabs = ( { questionnaire, selected, setSelected }: IProps ) => {
  const cart = useStore( $cart );
  const onSelect = ( index: number ) => {
    if ( setSelected && questionnaire?.prices && questionnaire?.prices.length ) {
      const price = questionnaire?.prices[ 0 ];
      const sel: ICartProduct = {
        questionnaireId: questionnaire?.id || '',
        priceId: price.id,
        price: index === 0 ? price.priceInApartment : price?.priceInDeparture,
        options: [],
        needDelivery: index === 1
      };
      setSelected( sel );
    }
  };

  useEffect( () => {
    if ( !selected ) onSelect( 0 );
  }, [ cart?.id, questionnaire?.id ] );

  if ( !selected && setSelected ) return <div><Spinner/></div>;

  return (
    <TabContainer
      tabs={ [ { label: 'to visit', value: 'to visit' }, { label: 'delivery', value: 'delivery' } ] }
      onSelect={ onSelect }
      className={ styles.tabContainer }
      defaultIndex={ selected?.needDelivery ? 1 : 0 }
      forceRenderTabPanel={ false }
    >
      <TabPanel>
        <Prices
          code={ 'priceInApartment' }
          selected={ selected }
          setSelected={ setSelected }
          questionnaire={ questionnaire }
        />
      </TabPanel>
      <TabPanel>
        <Prices
          code={ 'priceInDeparture' }
          selected={ selected }
          setSelected={ setSelected }
          questionnaire={ questionnaire }
          needDelivery={ true }
        />
      </TabPanel>
    </TabContainer>
  );
};

export default PricesTabs;
