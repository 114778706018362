import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './static/styles/index.scss';
import ConfigProvider from 'antd/es/config-provider';
import locale from 'antd/lib/locale/ru_RU';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import Spinner from './components/common/Spinner/Spinner';
import { ThemeProvider } from './providers/ThemeProvider';

const root = ReactDOM.createRoot( document.getElementById( 'root' ) as HTMLElement );

export const queryClient = new QueryClient( {
  defaultOptions: {
    queries: {
      staleTime: 0,
      cacheTime: 86400,
      refetchOnWindowFocus: false,
      retry: 0,
      keepPreviousData: true
    },
  },
} );

root.render(
  <ConfigProvider locale={ locale }>
    <BrowserRouter>
      <QueryClientProvider client={ queryClient }>
        <Suspense fallback={ <div><Spinner/></div> }>
          <ThemeProvider>
            <App/>
          </ThemeProvider>
        </Suspense>
      </QueryClientProvider>
    </BrowserRouter>
  </ConfigProvider>
);
