import React, { ReactNode } from 'react';
import styles from './CollapsedFilter.module.scss';
import { ReactComponent as CollapseArrow } from '../../../../static/imgs/icons/collapseArrow.svg';
import { ReactComponent as CollapseArrowPink } from '../../../../static/imgs/icons/collapseArrowPink.svg';
import cn from 'classnames';
import { randomString } from '../../../../util/randomString';

interface IProps {
  text?: string;
  children?: ReactNode;
  styleText?: any;
  style?: any;
  pink?: boolean;
  checked?: boolean;
}

const CollapsedWrapper = ( { text, children, styleText, style, pink, checked }: IProps ) => {
  const randomId = randomString( 10 );

  return (
    <div className={ styles.collapseItem } style={ style }>
      <input id={ randomId } className={ styles.keyBig } type="checkbox" defaultChecked={ checked }/>
      <label htmlFor={ randomId } className={ styles.collapseLabel }>
        <span style={ styleText }>{ text }</span>
        { pink ? <CollapseArrowPink/> : <CollapseArrow/> }
      </label>
      <div className={ cn( styles.inner, styles.componentContainer ) }>
        { children }
      </div>
    </div>
  );
};

export default CollapsedWrapper;