import { useContext, useLayoutEffect } from 'react';
import { IContext, ThemeContext } from '../providers/ThemeProvider';

export const useTheme = () => {
  const { theme, setTheme } = useContext<IContext>( ThemeContext );

  useLayoutEffect( () => {
    document.documentElement.setAttribute( 'data-theme', theme );
    localStorage.setItem( 'app-theme', theme );
  }, [ theme ] );

  return { theme, setTheme };
};
