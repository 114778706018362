export const ACCEPT_WAIT_TIME_MINUTES = 10;

export enum ICartStatus {
  CREATED = 0,
  WAITING = 1,
  CONFIRMED = 2
}

export enum ICartProductStatus {
  CREATED = 0,
  CONFIRMED = 1,
  REJECTED = 2
}

// default cart
export interface ICartDefault {
  id?: number;
  deliveryAddress?: string;
  status?: number;
  total?: number;
  updatedAt?: string;
  needDelivery?: boolean;
}

export interface ICart extends ICartDefault {
  products?: ICartProduct[];
}

export interface ICartProduct {
  id?: number;
  questionnaireId: string;
  priceId?: number;
  price: number;
  arrivalTime?: string;
  time?: string;
  status?: number;
  needDelivery?: boolean;
  options?: ICartOption[];
}

export interface ICartOption {
  serviceId: number;
  price?: number;
  name?: string;
}

// cart details
export interface ICartDetails extends ICartDefault {
  products: ICartDetailProduct[];
}

export interface ICartDetailProduct extends ICartProduct {
  questionnaire: ICartQuestionnaire;
}

export interface ICartQuestionnaire {
  id: string;
  age: number;
  name: string;
  photo: string;
  gender: string;
}

// model new order wait
export interface ICartWaiting extends ICartDefault {
  id: number;
  customer: ICartWaitingCustomer;
  products: ICartDetailProduct[];
}

export interface ICartWaitingCustomer {
  id: string;
  username: string;
  firstname: string;
  image: string;
  gender: string;
  age: number;
  rating: number;
}
