import {apiGet, apiPost} from '../api';
import { createEvent, createStore } from 'effector';
import { IQuestionnaire, IQuestionnaireEdit } from '../types/questionnaires';

export const updateModelInfo = createEvent<IQuestionnaire>();
export const clearModelInfo = createEvent();

export const $modelInfo = createStore<IQuestionnaire | null>( null )
  .on( updateModelInfo, ( state, newState ) => newState )
  .reset( clearModelInfo );

// Model
export const getModelInfo = async () => {
  const response = await apiGet( {
    url: '/model/questionnaire',
  } );

  updateModelInfo( response );

  return response;
};

export const saveModelInfo = async ( postData: IQuestionnaireEdit | FormData ) => {
  const response = await apiPost( {
    url: '/model/questionnaire',
    postData
  } );

  updateModelInfo( response );

  return response;
};

export const saveModelAddress = async ( place: {} ) => {
  const response = await apiPost( {
    url: '/model/questionnaire/address',
    postData: place
  } );

  updateModelInfo( response );

  return response;
};


