import React, { useRef } from 'react';
import styles from './ProductPhotoSlider.module.scss';
import './productPhoto.scss';
import Slider from 'react-slick';
import { settings } from './settings';
import { staticResource } from '../../../../util/resources';
import CustomModelImage from '../../UI/CustomModelImage/CustomModelImage';
import cn from 'classnames';

interface IProps {
  photos: string[];
  gender: string;
}

const ProductPhotoSlider = ( { photos, gender }: IProps ) => {
  const ref = useRef<Slider>( null );
  const dotRef = useRef<Slider>( null );

  const Paging = ( { photo, index }: any ) =>
    // @ts-ignore
    <a onClick={ () => ref.current.slickGoTo( index ) }>
      <img src={ staticResource( photo, 100, 150 ) } alt={ '' }/>
    </a>;

  const onChange = ( currentSlide: number, nextSlide: number ) => {
    // @ts-ignore
    if ( nextSlide !== dotRef.current?.innerSlider.state.currentSlide ) {
      dotRef.current?.slickGoTo( nextSlide );
    }
  };

  return (
    <>
      <Slider
        ref={ ref }
        beforeChange={ onChange }
        { ...settings }
        className={ cn( styles.sliderWrapper, 'ms-auto' ) }
      >
        { photos?.map( photo =>
          <div key={ photo }>
            <CustomModelImage
              src={ staticResource( photo, 300, 500 ) }
              alt={ '' }
              style={ { borderRadius: 15, marginLeft: 'auto' } }
              gender={ gender }
            />
          </div>
        ) }
      </Slider>

      <Slider
        ref={ dotRef }
        { ...settings }
        slidesToShow={ 6 }
        // infinite={ f }
        className={ cn( styles.sliderDotsWrapper, 'questionnaire-slider-dots' ) }
      >
        { photos?.map( ( dot, i ) =>
          <Paging key={ i } photo={ dot } index={ i }/>
        ) }
      </Slider>
    </>
  );
};

export default ProductPhotoSlider;
