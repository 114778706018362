import {createEvent, createStore} from 'effector';

export interface IAlert {
  type: 'warning' | 'danger' | 'success' | 'info';
  message?: string;
}

export const updateAlert = createEvent<IAlert>();
export const clearAlert = createEvent();

export const $alert = createStore<IAlert>( { type: 'success' } )
  .on( updateAlert, ( _, newState ) => newState )
  .reset( clearAlert );
