import React, { useRef } from 'react';
import styles from './Balance.module.scss';
import { localization } from '../../../data/localization';
import FormInput from '../../../components/common/Form/FormInput';
import ColoredButton from '../../../components/common/Buttons/ColoredButton/ColoredButton';
import { useStore } from 'effector-react';
import { $userInfo } from '../../../store/account';
import { IPayment } from '../../../types/payments';
import { createPayment, showPaymentModal } from '../../../store/payments';
import { ReactComponent as MoneroLogo } from '../../../static/imgs/icons/monero-logo.svg';

const UpBalance = () => {
  const userInfo = useStore( $userInfo );
  const input = useRef<any>( null );

  const pay = async () => {
    const amount = Number( input.current?.value );
    const response: IPayment = await createPayment( amount );

    showPaymentModal( response );
  };

  return (
    <div className={ styles.balanceWrapper }>
      <div className={ styles.balance }>
        <div className={ styles.text }>{ localization.menu.balance }</div>
        <div className={ styles.digit }>${ userInfo?.balance }</div>
      </div>
      <div className={ styles.currencyWrapper }>

        <button className={ styles.currencyBtn }>
          <MoneroLogo width={ 30 } height={ 30 }/>
          {/*<img src={ s.value } width={ 30 } height={ 30 } alt=""/>*/ }
        </button>

      </div>
      <FormInput
        inputRef={ input }
        // name={ EProfile.Balance }
        inputClass={ styles.inputClass }
        placeholder={ localization.common.enterValue }
        type={ 'number' }
      />
      <ColoredButton
        onClick={ pay }
        text={ localization.buttons.upBalance }
        className={ styles.btnClass }
      />
    </div>
  );
};

export default UpBalance;
