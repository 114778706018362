import {apiGet, apiPost} from '../api';
import {IQuestionnaireEdit} from '../types/questionnaires';
import {ICompany} from '../types/company';
import {createEvent, createStore} from 'effector';

export const updateBrothelInfo = createEvent<ICompany>();
export const clearBrothelInfo = createEvent();

export const $brothelInfo = createStore<ICompany | null>( null )
  .on( updateBrothelInfo, ( state, newState ) => newState )
  .reset( clearBrothelInfo );

export const getBrothelInfo = async () => {
  const response = await apiGet({
    url: '/brothel',
  });

  updateBrothelInfo(response);

  return response;
};

export const saveBrothelInfo = async (postData: IQuestionnaireEdit | FormData ) => {
  const response = await apiPost({
    url: '/brothel',
    postData
  });

  updateBrothelInfo(response);

  return response;
};

export const saveBrothelAddress = async (place: {} ) => {
  const response = await apiPost({
    url: '/brothel/address',
    postData: place
  });

  updateBrothelInfo(response);

  return response;
};

export const getBrothelModels = async () => {
  return await apiGet({
    url: '/brothel/questionnaires',
  });
};

export const getBrothelModelInfo = async (id: string) => {
  return await apiGet({
    url: `/brothel/questionnaires/${id}`,
  });
};

export const saveBrothelModelInfo = async (id: string,  postData: IQuestionnaireEdit | FormData ) => {
  return await apiPost({
    url: `/brothel/questionnaires/${id}`,
    postData
  });
};

export const saveBrothelModelAddress = async (id: string, place: {} ) => {
  return await apiPost({
    url: `/brothel/questionnaires/${id}/address`,
    postData: place
  });
};

