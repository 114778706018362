import React, { ReactNode } from 'react';
import 'react-tabs/style/react-tabs.css';
import './tabs.scss';
import { Tab, TabList, Tabs } from 'react-tabs';

export interface ITabsButton {
  value: string;
  label: string;
}

interface ITabContainerProps {
  children?: ReactNode;
  tabs?: ITabsButton[];
  className?: string;
  onSelect?: any;
  defaultIndex?: number;
  forceRenderTabPanel?: boolean;
}

const TabContainer = (
  {
    children,
    tabs,
    className,
    onSelect,
    defaultIndex,
    forceRenderTabPanel = true
  }: ITabContainerProps
) => {
  return (
    <Tabs forceRenderTabPanel={ forceRenderTabPanel } onSelect={ onSelect } defaultIndex={ defaultIndex }>
      <TabList className={ className }>
        { tabs?.map( tab =>
          <Tab key={ tab.value }>{ tab.label }</Tab>
        ) }
      </TabList>

      { children }
    </Tabs>
  );
};

export default TabContainer;
